<template>
  <div class="c-app flex-row align-items-center">
    <CContainer>
      <CRow class="justify-content-center">
        <CCol md="8">
          <CCardGroup>
            <CCard class="p-4">
              <CCardBody>
                <CForm @submit.prevent="UserLogin">
                  <h1>تسجيل الدخول</h1>
                  <p class="text-muted">تسجيل الدخول إلى حسابك</p>
                  <CInput placeholder="البريد الالكتروني" v-model="user.email">
                    <template #prepend-content
                      ><CIcon name="cil-user"
                    /></template>
                  </CInput>
                  <CInput
                    placeholder="كلمة المرور"
                    type="password"
                    v-model="user.password"
                  >
                    <template #prepend-content
                      ><CIcon name="cil-lock-locked"
                    /></template>
                  </CInput>
                  <CRow>
                    <CCol col="6" class="text-left">
                      <CButton color="primary" type="submit" class="px-4"
                        >تسجيل الدخول</CButton
                      >
                    </CCol>
                  </CRow>
                </CForm>
              </CCardBody>
            </CCard>
          </CCardGroup>
        </CCol>
      </CRow>
    </CContainer>
  </div>
</template>

<script>
import swal from "sweetalert";
import VueSession from "vue-session";

export default {
  name: "Login",
  data() {
    return {
      user: [],
    };
  },
  methods: {
    UserLogin: function () {
      let self = this;
      let formData = new FormData();
      formData.append("email", this.user.email);
      formData.append("password", this.user.password);
      console.log(formData);
      this.$http
        .post(`${process.env.VUE_APP_URL}login`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            "Access-Control-Allow-Origin": "*",
          },
        })
        .then((response) => {
          if (!response.data.error) {
            localStorage.token = response.data.data.token;
            this.$router.push({ name: "الرئيسية" });
          } else {
            swal({ title: response.data.message, buttons: "تم" });
          }
        });
    },
  },
};
</script>
